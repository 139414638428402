.card {
  margin-right: 5px;

  @include media-breakpoint-up(lg) {
    margin-right: 10px;
  }

  &--inner {
    position: relative;
    margin-top: 2px;
    box-shadow: 0 0 0 0 $primary;
    // transition: box-shadow 0.3s ease;

    @include media-breakpoint-up(lg) {
      &:hover {
        .image-wrap {
          // border: solid 2px $primary;
          filter: brightness(0.7);
          box-shadow: 0 0 0 2px $primary, 0 5px 10px 0px rgba(black, 0.3);

          &:before {
            opacity: 0.4;
          }
        }
      }
    }
  }

  .full-link {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .image-wrap {
    width: 100%;
    padding-bottom: 150%;
    position: relative;
    border-radius: 4px;
    // transition: all 0.1s ease;
    overflow: hidden;
    background: $dark-grey;
    box-shadow: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: radial-gradient(
        farthest-corner at 50% 50%,
        rgba(50, 50, 50, 0.5) 50%,
        #323232 100%
      );
      z-index: 3;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:after {
      content: "";
      position: absolute;
      top: -10px;
      right: -10px;
      height: 20px;
      width: 20px;
      background: $good;
      transform: rotate(45deg) translateY(-20px);
      pointer-events: none;
      transition: all 0.3s ease;

      @include media-breakpoint-up(lg) {
        top: -20px;
        right: -20px;
        height: 40px;
        width: 40px;
      }
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      // opacity: 0;
    }

    .no-poster {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $dark-grey;
    }
  }

  .quick-req {
    display: none;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 40px;
      height: 40px;
      z-index: 99;
      display: flex;
      padding: 10px;
      border-radius: 40px;
      background: rgba($black, 0.5);
      cursor: pointer;
      opacity: 0;

      svg {
        fill: $grey-light;
        width: 100%;
      }
    }

    &:hover {
      background: rgba($black, 0.8);

      svg {
        fill: $primary;
      }
    }
  }

  .text-wrap {
    padding-top: 5px;
    overflow: hidden;
    color: white;

    @include media-breakpoint-up(lg) {
      padding-top: 10px;
    }

    .title {
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
      white-space: nowrap;

      @include media-breakpoint-up(lg) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    .year {
      display: block;
      color: $grey-light;
      font-weight: normal;
    }

    p {
      margin: 0;
      color: white;
      line-height: 20px;
      font-size: 13px;
    }

    .role {
      color: $grey-light;
    }
  }

  &.on-server {
    .image-wrap {
      &:after {
        background: $good;
        transform: rotate(45deg) translateY(0);
      }
    }
  }

  &.requested {
    .image-wrap {
      &:after {
        background: $blue;
        transform: rotate(45deg) translateY(0);
      }
    }
  }

  &.person-card {
    .image-wrap {
      transition: none;
    }
  }

  .full-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  &:hover {
    .quick-req {
      opacity: 1;
    }
  }

  // @include media-breakpoint-up(lg) {
  //   &.img-not-loaded {
  //     img {
  //       opacity: 0;
  //     }
  //   }

  //   &.img-loaded {
  //     img {
  //       opacity: 1;
  //       transition: opacity 0.4s ease;
  //     }
  //   }
  // }
}

.person-card {
  .image-wrap {
    padding-bottom: 100%;
    border-radius: 500px !important;
    overflow: hidden !important;
    transform: translateZ(0);
  }

  .text-wrap {
    text-align: center;

    .known-for {
      margin-top: 5px;

      a {
        margin: 2px 5px;
        word-wrap: none;
        display: inline-block;
        position: relative;
        z-index: 5;
        color: white;
        font-size: 13px;
      }
    }

    .character {
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.popular-card {
  position: relative;

  &--count {
    z-index: 3;
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background: rgba($dark-grey, 0.9);
    color: $primary;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-weight: bold;
    display: none;
  }
}

.request-count {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba($blue, 0.8);
  z-index: 3;
  font-size: 12px;
  border-radius: 20px;
}

.company-card {
  margin-right: 5px;
  border-radius: 5px;

  @include media-breakpoint-up(lg) {
    margin-right: 10px;
  }

  &--inner {
    position: relative;
    margin-top: 2px;
    margin-bottom: 2px;
    box-shadow: 0 0 0 0 $primary;
    // transition: box-shadow 0.3s ease;
    height: 150px;
    background: rgba($black, 0.5);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: 200px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: radial-gradient(
        farthest-corner at 50% 50%,
        rgba(50, 50, 50, 0.5) 50%,
        #323232 100%
      );
      z-index: 3;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &--image {
    height: 100%;
    width: 100%;
    position: relative;

    img {
      opacity: 1;
      filter: grayscale(1) invert(1);
      height: 100%;
      width: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .full-link {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 5;
  }

  // @include media-breakpoint-up(lg) {
  //   &.img-not-loaded {
  //     img {
  //       opacity: 0;
  //     }
  //   }

  //   &.img-loaded {
  //     img {
  //       opacity: 1;
  //       transition: opacity 0.4s ease;
  //     }
  //   }
  // }

  &:hover {
    @include media-breakpoint-up(lg) {
      .company-card {
        &--inner {
          // background: $black;
          filter: brightness(0.7);
          box-shadow: 0 0 0 2px $primary, 0 5px 10px 0px rgba(black, 0.3);

          &:before {
            opacity: 0.4;
          }
        }
      }
    }
  }
}
