.season {
  &-wrap {
    // max-height: 100%;
    // overflow: hidden;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    @include media-breakpoint-down(md) {
      background: none !important;
    }
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
  }

  &-details {
    width: 100%;
    position: relative;

    @include media-breakpoint-up(lg) {
      width: 40%;
    }

    .stick {
      position: sticky;
      top: 0;
      @include media-breakpoint-up(lg) {
        &:after {
          content: "";
          position: absolute;
          bottom: -50px;
          left: -50px;
          width: 100vw;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 30%,
            rgba(0, 0, 0, 0.700717787114846) 65%,
            rgba(0, 0, 0, 0) 100%
          );
          height: 60vh;
          z-index: -1;
        }
      }

      @include media-breakpoint-up(lg) {
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .main-title {
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }

      .btn {
        margin-bottom: 20px;
      }
    }

    .overview {
      max-width: 80%;
      font-size: 12px;
      line-height: 16px;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  &-episodes {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 10px;
  }

  &-episode {
    width: 50%;
    padding: 0 10px 10px;

    @include media-breakpoint-up(md) {
      width: calc(100% / 3);
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% / 3);
    }

    @include media-breakpoint-up(xl) {
      width: calc(100% / 4);
    }

    .ep-num {
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 8px;
    }

    .not-aired {
      color: $bad;
    }

    .aired {
      color: $green;
    }

    .detail {
      // font-weight: 500;
      max-height: calc(16px * 5);
      overflow: hidden;
    }

    &--img {
      padding-bottom: 60%;
      position: relative;
      overflow: hidden;
      border-radius: 5px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }
}
